<template>
  <span>
    <v-row
      v-if="
        (competencia_de || competencia_ate) && temas_selecionados.length > 0
      "
      class="mx-auto"
      justify="center"
    >
      <div class="mt-2 body-1 font-weight-bold">
        Há
        <span class="error--text">{{ qtdIndicadoresErros }}</span> indicador(es)
        com erros de validação
      </div>
    </v-row>

    <v-row class="mx-auto" v-if="competencia_de || competencia_ate">
      <v-col cols="12" class="mt-2">
        <v-data-table
          multi-sort
          dense
          :headers="headers"
          :items="filteredItems"
          :options="{ multiSort: false }"
          loading-text="Carregando tabela"
          :headerProps="{ sortByText: 'Ordenar Por' }"
          :footer-props="{
            itemsPerPageText: 'Itens por Página',
            itemsPerPageOptions: [10, 50, 100, 250, 500],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
          class="row-cursor-pointer"
          @click:row="handleClick"
        >
          <template
            v-for="headerValue in chavesIndicadores.concat(
              indicadoresLiberados
            )"
            v-slot:[getHeaderSlotName(headerValue.field)]="item"
          >
            <span :key="headerValue.field">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.header.text }}
                  </span>
                </template>
                <span>
                  {{ headerValue.brief_description }}
                </span>
              </v-tooltip>
              <div
                v-if="!chavesIndicadores.includes(headerValue)"
                class="caption"
                :class="
                  qtdErrosPorIndicador[headerValue.field] > 0
                    ? 'error--text'
                    : 'success--text'
                "
              >
                {{ qtdErrosPorIndicador[headerValue.field] }} erro(s)
              </div>
            </span>
          </template>
          <template v-slot:[`item.erros`]="{ item }">
            <span :class="item.erros > 0 ? 'error--text font-weight-bold' : ''">
              {{ item.erros }}
            </span>
          </template>
          <template
            v-for="indicador in indicadoresLiberados"
            v-slot:[getItemSlotName(indicador.field)]="{ item }"
          >
            <span
              :key="indicador.field"
              :class="
                item[`tipo_validacao_${indicador.field}`] === 'ERRO_CRITICO'
                  ? 'error--text font-weight-bold'
                  : ''
              "
            >
              {{
                item[indicador.field] != undefined &&
                item[indicador.field] != null
                  ? item[indicador.field]
                  : "-" | parseNumberToFloatBrIgnoringNaN
              }}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogDetalhes"
      :max-width="920"
      @click:outside="dialogDetalhes = false"
      @keydown:esc="dialogDetalhes = false"
      :scrollable="true"
    >
      <v-card v-if="dialogDetalhes">
        <v-card-title class="pt-2 headline">
          Detalhes validação
        </v-card-title>
        <v-card-text class="pt-2 pb-0">
          <v-data-table
            :headers="dialogDetalhesHeader"
            :items="dialogDetalhesData"
            hide-default-footer
            class="elevation-1 style-table-dialog-detalhes"
            disable-pagination
            :mobile-breakpoint="10"
          >
            <template v-slot:[`item.parametro`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.parametro }}
                  </span>
                </template>
                <span>
                  {{ item.brief_description }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.valor`]="{ item }">
              <span
                v-if="
                  chavesIndicadores.find(
                    (chave) => chave.field === item.parametro.toLowerCase()
                  )
                "
              >
                {{ item.valor }}
              </span>
              <span v-else>
                {{
                  item.valor != undefined && item.valor != null
                    ? item.valor
                    : "-" | parseNumberToFloatBrIgnoringNaN
                }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                v-if="item.status"
                class="font-weight-medium"
                :class="
                  {
                    VALIDO: 'success--text',
                    ALERTA: 'warning--text',
                    ERRO_CRITICO: 'error--text font-weight-black',
                  }[item.tipo_status]
                "
              >
                {{ item.status || "-" }}
              </span>
              <span v-else>-</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pt-0 pr-6">
          <v-spacer />
          <v-btn text color="primary" @click="dialogDetalhes = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import IndicadoresComerciaisV2Service from "@/services/IndicadoresComerciaisV2Service.js";

  export default {
    name: "ComercialV2VisaoDetalhadaMunicipio",
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      mostrarApenasErros: {
        type: Boolean,
        default: false,
      },
      chavesIndicadores: {
        type: Array,
        required: true,
      },
      indicadoresLiberados: {
        type: Array,
        required: true,
      },
      codigoMunicipio: {
        type: String,
        default: "",
      },
    },
    data: () => ({
      headers: [],
      items: [],
      dialogDetalhes: false,
      dialogDetalhesHeader: [
        { text: "Campo", value: "parametro", sortable: false },
        { text: "Valor", value: "valor", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      dialogDetalhesData: [],
    }),
    computed: {
      filteredItems() {
        let items = JSON.parse(JSON.stringify(this.items));

        if (this.mostrarApenasErros == true) {
          items = items.filter((item) => item.erros > 0);
        }
        if (this.codigoMunicipio != "" && this.codigoMunicipio != null) {
          items = items.filter((item) => {
            return (
              item.com_002.includes(this.codigoMunicipio) ||
              item.nome_municipio
                .toLowerCase()
                .includes(this.codigoMunicipio.toLowerCase())
            );
          });
        }

        return items;
      },
      qtdIndicadoresErros() {
        return this.items.map((item) => item.erros).reduce((a, b) => a + b, 0);
      },
      qtdErrosPorIndicador() {
        let qtdErrosPorIndicador = {};

        this.indicadoresLiberados.forEach(
          (indicador) => (qtdErrosPorIndicador[indicador.field] = 0)
        );

        this.indicadoresLiberados.forEach((indicador) => {
          this.items.forEach((item) => {
            if (item[`tipo_validacao_${indicador.field}`] === "ERRO_CRITICO") {
              qtdErrosPorIndicador[indicador.field] += 1;
            }
          });
        });

        return qtdErrosPorIndicador;
      },
    },
    mounted() {
      this.getDados();
    },
    methods: {
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        temas_selecionados = this.temas_selecionados
      ) {
        this.$emit("updateLoadingDados", true);

        IndicadoresComerciaisV2Service.getDadosComValidacoes(
          competencia_de,
          competencia_ate,
          temas_selecionados.join(",")
        )
          .then((res) => {
            this.setHeaders();
            this.items = res;
          })
          .catch((err) => {
            this.$toast.error(
              "Erro ao recuperar Validações dos Indicadores.",
              "",
              {
                position: "topRight",
                timeout: 5000,
              }
            );
            throw err;
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
      setHeaders() {
        this.headers = [
          { text: "Erros", value: "erros" },
          { text: "Município", value: "nome_municipio" },
        ];

        this.chavesIndicadores.forEach((chave) => {
          this.headers.push({
            text: chave.field.toUpperCase(),
            value: chave.field,
          });
        });

        this.indicadoresLiberados.forEach((indicador) => {
          this.headers.push({
            text: indicador.field.toUpperCase(),
            value: indicador.field,
          });
        });

        this.headers.sort();
      },
      downloadArquivoValidacoes() {
        this.$emit("updateDownloadingArquivoValidacoes", true);

        this.$toast.info("Preparando seu arquivo para download.", "", {
          position: "topRight",
          timeout: 3000,
        });

        IndicadoresComerciaisV2Service.downloadArquivoValidacoesVisaoMunicipio(
          this.competencia_de,
          this.competencia_ate,
          this.temas_selecionados.join(","),
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            let nomeArquivo = await this.getNomeArquivoValidacoes();
            link.setAttribute("download", `${nomeArquivo}.xlsx`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() =>
            this.$emit("updateDownloadingArquivoValidacoes", false)
          );
      },
      getNomeArquivoValidacoes() {
        return IndicadoresComerciaisV2Service.getNomeArquivoValidacoes()
          .then((res) => res.data.nome)
          .catch((err) => {
            this.$toast.error("Erro ao recuperar nome do arquivo.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getHeaderSlotName(name) {
        return `header.${name}`;
      },
      getItemSlotName(name) {
        return `item.${name}`;
      },
      handleClick(items) {
        this.openDialogDetalhes(items);
      },
      openDialogDetalhes(items) {
        this.dialogDetalhesData = [];

        this.chavesIndicadores
          .concat(this.indicadoresLiberados)
          .forEach((key) => {
            this.dialogDetalhesData.push({
              parametro: key.field.toLocaleUpperCase(),
              valor: items[key.field],
              status: items[`validacao_${key.field}`],
              tipo_status: items[`tipo_validacao_${key.field}`],
              brief_description: key.brief_description,
            });
          });

        this.dialogDetalhes = true;
      },
    },
  };
</script>

<style>
  .row-cursor-pointer tbody tr :hover {
    cursor: pointer;
  }

  .v-data-table table thead tr th {
    font-weight: 300;
    font-size: 14.5px;
    margin: 0px 0px;
    padding-left: 8px;
    padding-right: 24px;
  }

  .v-data-table table tbody tr td {
    font-size: 13px;
  }

  .style-table-dialog-detalhes table thead tr th {
    font-weight: 400;
    font-size: 14px;
    height: 33px;
  }

  .style-table-dialog-detalhes table tbody tr td {
    word-wrap: break-word;
    max-width: 240px;
    font-size: 12.5px;
    padding: 0px 8px !important;
    height: 35px;
  }

  .style-table-dialog-detalhes table tbody tr td .v-textarea {
    padding-top: 9px !important;
    margin-left: 9px !important;
  }

  .style-table-dialog-detalhes table tbody tr td .v-textarea textarea {
    font-size: 12.8px;
    color: rgba(0, 0, 0, 0.911);
    font-weight: 300;
  }
</style>
