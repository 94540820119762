var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(
      (_vm.competencia_de || _vm.competencia_ate) && _vm.temas_selecionados.length > 0
    )?_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"center"}},[_c('div',{staticClass:"mt-2 body-1 font-weight-bold"},[_vm._v(" Há "),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.qtdIndicadoresErros))]),_vm._v(" indicador(es) com erros de validação ")])]):_vm._e(),(_vm.competencia_de || _vm.competencia_ate)?_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-cursor-pointer",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.filteredItems,"options":{ multiSort: false },"loading-text":"Carregando tabela","headerProps":{ sortByText: 'Ordenar Por' },"footer-props":{
          itemsPerPageText: 'Itens por Página',
          itemsPerPageOptions: [10, 50, 100, 250, 500],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([_vm._l((_vm.chavesIndicadores.concat(
            _vm.indicadoresLiberados
          )),function(headerValue){return {key:_vm.getHeaderSlotName(headerValue.field),fn:function(item){return [_c('span',{key:headerValue.field},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(headerValue.brief_description)+" ")])]),(!_vm.chavesIndicadores.includes(headerValue))?_c('div',{staticClass:"caption",class:_vm.qtdErrosPorIndicador[headerValue.field] > 0
                  ? 'error--text'
                  : 'success--text'},[_vm._v(" "+_vm._s(_vm.qtdErrosPorIndicador[headerValue.field])+" erro(s) ")]):_vm._e()],1)]}}}),{key:"item.erros",fn:function(ref){
                  var item = ref.item;
return [_c('span',{class:item.erros > 0 ? 'error--text font-weight-bold' : ''},[_vm._v(" "+_vm._s(item.erros)+" ")])]}},_vm._l((_vm.indicadoresLiberados),function(indicador){return {key:_vm.getItemSlotName(indicador.field),fn:function(ref){
                  var item = ref.item;
return [_c('span',{key:indicador.field,class:item[("tipo_validacao_" + (indicador.field))] === 'ERRO_CRITICO'
                ? 'error--text font-weight-bold'
                : ''},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item[indicador.field] != undefined && item[indicador.field] != null ? item[indicador.field] : "-"))+" ")])]}}})],null,true)})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":920,"scrollable":true},on:{"click:outside":function($event){_vm.dialogDetalhes = false},"keydown:esc":function($event){_vm.dialogDetalhes = false}},model:{value:(_vm.dialogDetalhes),callback:function ($$v) {_vm.dialogDetalhes=$$v},expression:"dialogDetalhes"}},[(_vm.dialogDetalhes)?_c('v-card',[_c('v-card-title',{staticClass:"pt-2 headline"},[_vm._v(" Detalhes validação ")]),_c('v-card-text',{staticClass:"pt-2 pb-0"},[_c('v-data-table',{staticClass:"elevation-1 style-table-dialog-detalhes",attrs:{"headers":_vm.dialogDetalhesHeader,"items":_vm.dialogDetalhesData,"hide-default-footer":"","disable-pagination":"","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"item.parametro",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.parametro)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.brief_description)+" ")])])]}},{key:"item.valor",fn:function(ref){
                var item = ref.item;
return [(
                _vm.chavesIndicadores.find(
                  function (chave) { return chave.field === item.parametro.toLowerCase(); }
                )
              )?_c('span',[_vm._v(" "+_vm._s(item.valor)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBrIgnoringNaN")(item.valor != undefined && item.valor != null ? item.valor : "-"))+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(item.status)?_c('span',{staticClass:"font-weight-medium",class:{
                  VALIDO: 'success--text',
                  ALERTA: 'warning--text',
                  ERRO_CRITICO: 'error--text font-weight-black',
                }[item.tipo_status]},[_vm._v(" "+_vm._s(item.status || "-")+" ")]):_c('span',[_vm._v("-")])]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pt-0 pr-6"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialogDetalhes = false}}},[_vm._v(" Fechar ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }